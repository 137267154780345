import { FamilyMemberBox } from 'components/FamilyMemberBox/FamilyMemberBox';
import { normalizeArray } from 'lib/utils';
import { useTranslation } from 'react-i18next';
import { useUserStore } from 'stores/user.store';

type FamilyMembersProps = {
    editeable?: boolean;
};
export const FamilyMembers: React.FC<FamilyMembersProps> = ({ editeable = true }) => {
    const { user, getUser } = useUserStore(state => state);
    const { t } = useTranslation('member', { keyPrefix: 'familyInfo' });
    if (!user || (!user.children.edges.length && !user.pets.edges.length)) return null;

    return (
        <div className="flex flex-col gap-6">
            {user.children.edges.length > 0 && (
                <div className="flex flex-col gap-4">
                    <div className="text-sm text-zinc-700">{t('childrenTitle')}</div>
                    <div className="grid grid-cols-3 gap-6">
                        {normalizeArray(user?.children).map((child: any) => (
                            <FamilyMemberBox
                                member={child}
                                key={child.id}
                                editeable={editeable}
                                onUpdate={() => getUser(user?.id)}
                            />
                        ))}
                    </div>
                </div>
            )}

            {user.pets.edges.length > 0 && (
                <div className="flex flex-col gap-4">
                    <div className="text-sm text-zinc-700">{t('petsTitle')}</div>
                    <div className="grid grid-cols-3 gap-6">
                        {normalizeArray(user?.pets).map((pet: any) => (
                            <FamilyMemberBox
                                member={pet}
                                key={pet.id}
                                editeable={editeable}
                                onUpdate={() => getUser(user?.id)}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

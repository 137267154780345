import { Avatar } from 'components/Avatar/Avatar';
import { Button } from 'components/Button/Button';
import { FormError } from 'components/FormError/FormError';
import { Input } from 'components/Input/Input';
import { Label } from 'components/Label/Label';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { Textarea } from 'components/Textarea/Textarea';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type EditBalanceModalProps = {
    isOpen: boolean;
    onClose: () => void;
    member: any;
};

export const EditBalanceModal: React.FC<EditBalanceModalProps> = ({ isOpen, onClose, member }) => {
    const { t } = useTranslation('documents');
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (formData: any) => {
        console.log(formData);
    };

    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} maxWidth={800}>
                <div className="flex flex-col gap-8">
                    <div>
                        <div className="text-lg font-semibold text-zinc-700 mb-2">{t('editBalanceModalTitle')}</div>
                        <div className="text-sm text-zinc-500">{t('editBalanceModalSubtitle')}</div>
                    </div>
                    <div className="flex gap-2 items-center">
                        <Avatar image={member?.image} size={32} />
                        <div>
                            {member?.firstName} {member?.lastName}
                        </div>
                    </div>
                    <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <Label>{t('balanceInputLabel')}</Label>
                            <Input
                                {...register('balance', {
                                    required: t('balanceModalBalanceError'),
                                })}
                                placeholder={t('balanceInputPlaceholder')}
                                type="number"
                                error={!!errors.balance}
                            />
                            <FormError error={errors.balance} />
                        </div>
                        <div>
                            <Label>{t('noteInputLabel')}</Label>
                            <Textarea {...register('note')} placeholder={t('noteInputPlaceholder')} />
                        </div>
                        <div className="flex justify-end gap-2">
                            <Button variant="outline" type="button" onClick={onClose}>
                                {t('cancelModalButton')}
                            </Button>
                            <Button>{t('confirmModalButton')}</Button>
                        </div>
                    </form>
                </div>
            </Modal>
        </Portal>
    );
};

import { InputMask } from '@react-input/mask';
import { Button } from 'components/Button/Button';
import { FormError } from 'components/FormError/FormError';
import { Input } from 'components/Input/Input';
import { Label } from 'components/Label/Label';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { Select } from 'components/Select/Select';
import dayjs from 'dayjs';
import { SALUTATIONS } from 'lib/options';
import { EMAIL_REGEX, validateDate } from 'lib/utils';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type CreateMemberModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onCreate: (data: any) => void;
};
export const CreateMemberModal: React.FC<CreateMemberModalProps> = ({ isOpen, onClose, onCreate }) => {
    const { t } = useTranslation('company');
    const { t: f } = useTranslation('forms');

    const {
        register,
        handleSubmit,
        control,
        formState: { isValid, errors },
    } = useForm({
        mode: 'onChange',
    });

    const onSubmit = (formData: any) => {
        const today = dayjs().format('YYYY-MM-DD');
        formData.type = 'COMPANY';
        formData.status = 'DRAFT';
        formData.membershipStart = today;
        formData.maritalStatus = 'SINGLE';
        formData.dateOfBirth = formData.dateOfBirth
            ? dayjs(formData.dateOfBirth, 'DD.MM.YYYY').format('YYYY-MM-DD')
            : null;
        onCreate(formData);
    };

    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} maxWidth={800} uncloseable>
                <div className="mb-6">
                    <div className="text-lg font-semibold text-zinc-700 mb-2">{t('newMemberTitle')}</div>
                    <div className="text-zinc-500 text-sm">{t('newMemberText')}</div>
                </div>
                <form className="bg-zinc-50 p-6 flex flex-col gap-6">
                    <div className="flex gap-6">
                        <div className="flex-1">
                            <Label>{f('firstName')}</Label>
                            <Input
                                placeholder={f('firstName')}
                                {...register('firstName', {
                                    required: f('firstNameRequired'),
                                })}
                                error={!!errors.firstName}
                                autoFocus
                            />
                            <FormError error={errors.firstName} />
                        </div>
                        <div className="flex-1">
                            <Label>{f('lastName')}</Label>
                            <Input
                                placeholder={f('lastName')}
                                {...register('lastName', {
                                    required: f('lastNameRequired'),
                                })}
                                error={!!errors.lastName}
                            />
                            <FormError error={errors.lastName} />
                        </div>
                    </div>

                    <div className="flex gap-6">
                        <div className="flex-1">
                            <Label>{f('email')}</Label>
                            <Input
                                placeholder={f('email')}
                                {...register('email', {
                                    required: f('emailRequired'),
                                    pattern: EMAIL_REGEX,
                                })}
                                error={!!errors.email}
                            />
                            <FormError error={errors.email} />
                        </div>
                        <div className="flex-1">
                            <Label>{f('phone')}</Label>
                            <Input
                                placeholder={f('phone')}
                                {...register('phoneNumber', {
                                    required: f('phoneNumberRequired'),
                                })}
                                error={!!errors.phoneNumber}
                            />
                            <FormError error={errors.phoneNumber} />
                        </div>
                    </div>

                    <div className="flex gap-6">
                        <div className="flex-1">
                            <Label>{f('salutation')}</Label>
                            <Controller
                                control={control}
                                name="salutation"
                                rules={{ required: f('salutationRequired') }}
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        options={SALUTATIONS}
                                        onChange={(val: any) => onChange(val.value)}
                                        value={SALUTATIONS.find(s => s.value === value)}
                                        error={!!errors.salutation}
                                    />
                                )}
                            />
                            <FormError error={errors.salutation} />
                        </div>
                        <div className="flex-1">
                            <Label>{f('jobTitle')}</Label>
                            <Input
                                placeholder={f('jobTitle')}
                                {...register('jobPosition', {
                                    required: true,
                                })}
                                error={!!errors.jobPosition}
                            />
                            <FormError error={errors.jobPosition} />
                        </div>
                    </div>

                    <div className="flex gap-6">
                        <div className="flex-1">
                            <Label>{f('dateOfBirth')}</Label>
                            <InputMask
                                component={Input}
                                placeholder={f('datePlaceholder')}
                                mask="__.__.____"
                                replacement={{ _: /\d/ }}
                                error={!!errors.dateOfBirth}
                                {...register('dateOfBirth', {
                                    validate: value => validateDate(value) || f('invalidDateError'),
                                    required: f('dateOfBirthRequired'),
                                })}
                            />
                            <FormError error={errors.dateOfBirth} />
                        </div>
                        <div className="flex-1"></div>
                    </div>
                </form>
                <div className="flex justify-end gap-2 mt-4">
                    <Button variant="outline" onClick={onClose} type="button">
                        {t('cancelButton')}
                    </Button>
                    <Button type="submit" onClick={handleSubmit(onSubmit)} disabled={!isValid}>
                        {t('confirmButton')}
                    </Button>
                </div>
            </Modal>
        </Portal>
    );
};

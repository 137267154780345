import clsx from 'clsx';
import { Icon } from 'components/Icon/Icon';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ActionsDropdown.module.scss';

export const ActionsDropdown = ({ onEdit }: any) => {
    const [isActive, setIsActive] = useState(false);
    const menu = useRef<any>(null);
    const { t } = useTranslation('documents');

    const handleClickOutside = (event: any) => {
        if (menu.current && !menu.current.contains(event.target)) {
            setIsActive(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return (
        <div
            className={clsx(styles.tableActions, isActive && styles.active)}
            onClick={() => setIsActive(!isActive)}
            ref={menu}
        >
            <div className={styles.trigger}>
                <Icon name="three-dots" />
            </div>
            <div className={styles.dropdown}>
                <div className={styles.dropdownContent}>
                    <div className={styles.dropdownItem} onClick={onEdit}>
                        <Icon name="edit" /> {t('editBalanceButton')}
                    </div>
                </div>
            </div>
        </div>
    );
};

import { useMutation } from '@apollo/client';
import { Button } from 'components/Button/Button';
import { Card } from 'components/Card/Card';
import { Icon } from 'components/Icon/Icon';
import { useToast } from 'components/Toast/use-toast';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { EVENT_INVITE } from 'services/events/mutation';
import { useEventStore } from 'stores/event.store';

import { ConfirmInviteesModal } from './ConfirmInviteesModal';
import { EventInvitedMembers } from './EventInvitedMembers';
import { EventInviteMembersModal } from './EventInviteMembersModal';
import { EventSettings } from './EventSettings';

export const EventInfo = () => {
    const { event, getEvent, invite, setInvite } = useEventStore(state => state);
    const [inviteModal, setInviteModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
    const [all, setAll] = useState(false);
    const { t } = useTranslation('events');
    const { toast } = useToast();

    const [inviteMembersMutation] = useMutation(EVENT_INVITE);

    useEffect(() => {
        setInviteModal(invite);
    }, [invite]);

    const selectMember = (id: string) => {
        if (selectedMembers.includes(id)) {
            setSelectedMembers(selectedMembers.filter((member: any) => member !== id));
            return;
        }
        setSelectedMembers([...selectedMembers, id]);
    };

    const confirmInvite = (members: string[]) => {
        setSelectedMembers(members);
        setInviteModal(false);
        setConfirmModal(true);
    };

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSelectedMembers([]);
    };

    const inviteMembers = async () => {
        if (selectedMembers.length > 0 || all) {
            const resp = await inviteMembersMutation({
                variables: { event: event.id, members: selectedMembers, selectAll: all },
            });
            if (resp?.data?.eventInvite?.event?.id) {
                toast({
                    title: t('inviteMembersSuccess'),
                    variant: 'success',
                });
                setConfirmModal(false);
                setSelectedMembers([]);
                getEvent(event.id);
                setInvite(false);
            } else {
                failedToast();
            }
        }
    };

    const inviteAll = () => {
        setAll(true);
        setInviteModal(false);
        setConfirmModal(true);
    };

    const failedToast = () => {
        toast({
            title: t('createFailed'),
            description: t('createFailedDescription'),
            variant: 'warning',
        });
    };

    if (!event) return null;

    return (
        <>
            <Card className="flex flex-col gap-8 text-zinc-700">
                <div className="flex space-between">
                    <div className="flex flex-col gap-4 flex-1">
                        <div className="flex gap-5">
                            <div>{dayjs(event?.date).format('DD.MM.YYYY')}</div>
                            <div className="opacity-60">|</div>
                            <div>
                                {event.allDay ? (
                                    t('allDayLabel')
                                ) : (
                                    <>
                                        {dayjs(event.fromTime, 'HH:mm:ss').format('HH:mm')} -{' '}
                                        {dayjs(event.untilTime, 'HH:mm:ss').format('HH:mm')}
                                    </>
                                )}
                            </div>
                        </div>
                        <div>
                            {event.invited?.edges.length === 0 ? (
                                <Button onClick={() => setInviteModal(true)}>
                                    <Icon name="invitation" className="h-4 mr-2" />
                                    {t('inviteMembersButton')}
                                </Button>
                            ) : (
                                <EventInvitedMembers />
                            )}
                        </div>
                    </div>
                    <div>
                        <EventSettings />
                    </div>
                </div>

                {event.description && <div dangerouslySetInnerHTML={{ __html: event?.description }}></div>}

                {event.documents?.length > 0 && (
                    <div className="flex flex-col gap-2">
                        <div>{t('documentsLabel')}</div>
                        <div className="flex gap-4">
                            {event.documents?.map((doc: any) => (
                                <Button key={doc.id} asChild variant="outline" className="bg-rose-50 text-zinc-700">
                                    <Link to={doc.document.url} target="_blank">
                                        <Icon name="file" className="h-4 mr-2" />
                                        {doc.name}
                                    </Link>
                                </Button>
                            ))}
                        </div>
                    </div>
                )}
            </Card>
            <EventInviteMembersModal
                isOpen={inviteModal}
                onClose={() => {
                    setInviteModal(false);
                    setInvite(false);
                }}
                onInvite={confirmInvite}
                onMemberSelect={selectMember}
                selectedMembers={selectedMembers}
                inviteAllMembers={inviteAll}
            />
            <ConfirmInviteesModal isOpen={confirmModal} onClose={closeConfirmModal} onConfirm={inviteMembers} />
        </>
    );
};

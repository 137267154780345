import { useMutation } from '@apollo/client';
import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';
import { Input } from 'components/Input/Input';
import { Label } from 'components/Label/Label';
import { useToast } from 'components/Toast/use-toast';
import { Typography } from 'components/Typograhpy/Typography';
import { PASSWORD_REGEX, cn } from 'lib/utils';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { CONFIRM_RESET_PASSWORD } from 'services/users/mutation';

export const ResetPassword = () => {
    const { t } = useTranslation('auth');
    const { toast } = useToast();
    const { register, handleSubmit, watch } = useForm();
    const [confirmReset] = useMutation(CONFIRM_RESET_PASSWORD);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const onSubmit = async (data: any) => {
        console.log(data);
        if (data.password !== data.confirmPassword) {
            toast({
                description: t('passwordNotMatch'),
                variant: 'warning',
            });
            return;
        }
        const token = searchParams.get('token');
        const id = searchParams.get('id');
        if (!id || !token) return;
        const reset = await confirmReset({
            variables: {
                uuid: id,
                token,
                password: data.password,
            },
        });
        if (reset?.errors && reset?.errors?.length > 0) {
            toast({
                description: reset?.errors[0].message,
                variant: 'warning',
            });
        }
        if (reset?.data?.resetPasswordConfirm?.success) {
            toast({
                title: t('resetPassSuccess'),
                variant: 'success',
            });
            navigate(AppRoutes.login, { replace: true });
        }
    };

    const hasLength = watch('password')?.length >= 8;
    const hasOneCapital = /(?=.*[A-Z])/.test(watch('password'));
    const hasOneNumber = /(?=.*\d)/.test(watch('password'));

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="w-full max-w-[460px] flex flex-col gap-8">
            <Typography.H3>{t('newPassTitle')}</Typography.H3>
            <div className="flex flex-col gap-4">
                <div>
                    <Label>{t('newPassword')}</Label>
                    <Input
                        type="password"
                        placeholder={t('passwordPlaceholder')}
                        {...register('password', {
                            required: true,
                            pattern: PASSWORD_REGEX,
                        })}
                    />
                </div>
                <div>
                    <Label>{t('confirmPassword')}</Label>
                    <Input
                        type="password"
                        placeholder={t('passwordPlaceholder')}
                        {...register('confirmPassword', {
                            required: true,
                        })}
                    />
                </div>
                <div className="flex flex-col gap-4 text-sm">
                    <PassCondition condition={hasLength}>{t('condition8char')}</PassCondition>
                    <PassCondition condition={hasOneCapital}>{t('conditionOneCapital')}</PassCondition>
                    <PassCondition condition={hasOneNumber}>{t('conditionOneNumber')}</PassCondition>
                </div>
            </div>

            <div className="flex justify-end">
                <Button disabled={!hasLength || !hasOneCapital || !hasOneNumber}>{t('newPassButton')}</Button>
            </div>
        </form>
    );
};

const PassCondition = ({ condition, children }: any) => {
    return (
        <div className={cn('flex gap-1 items-center', condition ? 'text-green-500' : 'text-rose-500')}>
            <Icon name={condition ? 'check' : 'close'} className="h-4" /> {children}
        </div>
    );
};

export const SALUTATIONS = [
    { value: 'MR', label: 'Mr.' },
    { value: 'MS', label: 'Ms.' },
    { value: 'MRS', label: 'Mrs.' },
];

export const MATRIAL_STATUS = [
    { value: 'SINGLE', label: 'Single' },
    { value: 'MARRIED', label: 'Married' },
    { value: 'DIVORCED', label: 'Divorced' },
    { value: 'WIDOWED', label: 'Widowed' },
];

export const USER_TYPE = [
    { value: 'INDIVIDUAL', label: 'Individual' },
    { value: 'COMPANY', label: 'Company' },
];

export const GENDERS = [
    { value: 'MALE', label: 'Male' },
    { value: 'FEMALE', label: 'Female' },
    { value: 'OTHER', label: 'Other' },
];

export const PET_TYPES = [
    { value: 'DOG', label: 'Dog' },
    { value: 'CAT', label: 'Cat' },
    { value: 'OTHER', label: 'Other' },
];

export const EVENT_TYPES = [
    { value: 'MEMBER', label: 'Members-only event' },
    { value: 'PARTNER', label: 'Partner event' },
    { value: 'FAMILY', label: 'Family event' },
    { value: 'FRIEND', label: 'Open event' },
];

export enum USER_STATUS {
    ACTIVE,
    BLOCKED,
    DRAFT,
}

export const COUNTRIES = [
    { value: 'CH', label: 'Switzerland' },
    { value: 'DE', label: 'Germany' },
    { value: 'AT', label: 'Austria' },
    { value: 'FR', label: 'France' },
    { value: 'IT', label: 'Italy' },
    { value: 'GB', label: 'United Kingdom' },
    { value: 'US', label: 'United States' },
    { value: 'RS', label: 'Serbia' },
];

export const ROLES = [
    { value: 'ADMIN', label: 'Admin' },
    { value: 'EXECUTIVE', label: 'Executive' },
    { value: 'EMPLOYEE', label: 'Employee' },
];

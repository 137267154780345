import { useMutation, useQuery } from '@apollo/client';
import { Card, CardSubtitle, CardTitle } from 'components/Card/Card';
import { Label } from 'components/Label/Label';
import { SelectCreatable } from 'components/Select/Select';
import { Textarea } from 'components/Textarea/Textarea';
import { normalizeArray } from 'lib/utils';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { create } from 'react-modal-promise';
import { ADD_FOOD, ADD_FOOD_ALLERGY, ADD_FOOD_RESTRICTION } from 'services/users/mutation';
import { ALLERGIES, DIET_RESTRICTIONS, FOOD_PREFERENCES } from 'services/users/query';

import { RemoveFoodModal } from './RemoveFoodModal';

type UserPreferencesProps = {
    user?: any;
    onRemove?: () => void;
};
export const UserPreferences: React.FC<UserPreferencesProps> = ({ user, onRemove }) => {
    const foods = useQuery(FOOD_PREFERENCES);
    const restrictions = useQuery(DIET_RESTRICTIONS);
    const allergies = useQuery(ALLERGIES);
    const { t } = useTranslation('member', { keyPrefix: 'preferences' });
    const { t: f } = useTranslation('forms');
    const [loading, setLoading] = useState(false);

    const [addLike] = useMutation(ADD_FOOD);
    const [addDislike] = useMutation(ADD_FOOD);
    const [addRestriction] = useMutation(ADD_FOOD_RESTRICTION);
    const [addAllergy] = useMutation(ADD_FOOD_ALLERGY);
    const { register, control, setValue } = useFormContext();

    const removeModal = create(RemoveFoodModal);

    // Pre-fill fields if in edit mode
    useEffect(() => {
        if (user) {
            setValue(
                'likes',
                normalizeArray(user.likes).map((l: any) => ({ value: l.id, label: l.name })),
            );
            setValue(
                'dislikes',
                normalizeArray(user.dislikes).map((d: any) => ({ value: d.id, label: d.name })),
            );
            setValue(
                'dietRestrictions',
                normalizeArray(user.dietRestrictions).map((l: any) => ({ value: l.id, label: l.name })),
            );
            setValue(
                'allergies',
                normalizeArray(user.allergies).map((l: any) => ({ value: l.id, label: l.name })),
            );
            setValue('preferenceComment', user.preferenceComment);
        }
    }, [user, setValue]);

    const confirmOptionRemove = (value: any, context: any, callback: any) => {
        context.action === 'remove-value'
            ? removeModal({ food: context.removedValue.label })
                  .then(_ => {
                      callback(value);
                      if (onRemove) onRemove();
                  })
                  .catch(() => {})
            : callback(value);
    };

    return (
        <Card>
            <CardTitle>{t('title')}</CardTitle>
            <CardSubtitle>{t('subtitle')}</CardSubtitle>

            <div className="flex flex-col gap-6">
                <div>
                    <Label>{f('likes')}</Label>
                    <Controller
                        control={control}
                        name="likes"
                        render={({ field: { onChange, value } }) => (
                            <SelectCreatable
                                isMulti
                                isLoading={loading}
                                placeholder={f('selectPlaceholder')}
                                options={foods.data?.userPreferences.map((d: any) => ({ label: d.name, value: d.id }))}
                                onCreateOption={(opt: any) => {
                                    setLoading(true);
                                    addLike({ variables: { name: opt } }).then(resp => {
                                        if (resp.data.userPreferenceCreate.preference) {
                                            const item = resp.data.userPreferenceCreate.preference;
                                            foods.refetch();
                                            value.push({
                                                value: item.id,
                                                label: item.name,
                                            });
                                            onChange(value);
                                        }
                                    });
                                    setLoading(false);
                                }}
                                onChange={(value, context) => confirmOptionRemove(value, context, onChange)}
                                value={value}
                            />
                        )}
                    />
                </div>
                <div>
                    <Label>{f('dislikes')}</Label>
                    <Controller
                        control={control}
                        name="dislikes"
                        render={({ field: { onChange, value } }) => (
                            <SelectCreatable
                                isMulti
                                placeholder={f('selectPlaceholder')}
                                options={foods.data?.userPreferences.map((d: any) => ({ label: d.name, value: d.id }))}
                                onCreateOption={(opt: any) => {
                                    setLoading(true);
                                    addDislike({ variables: { name: opt } }).then(resp => {
                                        console.log(resp);
                                        if (resp.data.userPreferenceCreate.preference) {
                                            const item = resp.data.userPreferenceCreate.preference;
                                            foods.refetch();
                                            value.push({
                                                value: item.id,
                                                label: item.name,
                                            });
                                            onChange(value);
                                        }
                                        setLoading(false);
                                    });
                                }}
                                onChange={(value, context) => confirmOptionRemove(value, context, onChange)}
                                value={value}
                            />
                        )}
                    />
                </div>
                <div>
                    <Label>{f('dietRestrictions')}</Label>
                    <Controller
                        control={control}
                        name="dietRestrictions"
                        render={({ field: { onChange, value } }) => (
                            <SelectCreatable
                                isMulti
                                placeholder={f('selectPlaceholder')}
                                options={restrictions.data?.userDietRestrictions.map((d: any) => ({
                                    label: d.name,
                                    value: d.id,
                                }))}
                                onCreateOption={(opt: any) => {
                                    setLoading(true);
                                    addRestriction({ variables: { name: opt } }).then(resp => {
                                        if (resp.data.userDietRestrictionCreate.diet) {
                                            const item = resp.data.userDietRestrictionCreate.diet;
                                            restrictions.refetch();
                                            value.push({
                                                value: item.id,
                                                label: item.name,
                                            });
                                            onChange(value);
                                        }
                                        setLoading(false);
                                    });
                                }}
                                onChange={(value, context) => confirmOptionRemove(value, context, onChange)}
                                value={value}
                            />
                        )}
                    />
                </div>
                <div>
                    <Label>{f('allergies')}</Label>
                    <Controller
                        control={control}
                        name="allergies"
                        render={({ field: { onChange, value } }) => (
                            <SelectCreatable
                                isMulti
                                placeholder={f('selectPlaceholder')}
                                options={allergies.data?.userAllergies.map((a: any) => ({
                                    label: a.name,
                                    value: a.id,
                                }))}
                                onCreateOption={(opt: any) => {
                                    setLoading(true);
                                    addAllergy({ variables: { name: opt } }).then(resp => {
                                        if (resp.data.userAllergyCreate.allergy) {
                                            const item = resp.data.userAllergyCreate.allergy;
                                            allergies.refetch();
                                            value.push({
                                                value: item.id,
                                                label: item.name,
                                            });
                                            onChange(value);
                                        }
                                        setLoading(false);
                                    });
                                }}
                                onChange={(value, context) => confirmOptionRemove(value, context, onChange)}
                                value={value}
                            />
                        )}
                    />
                </div>
            </div>
            <div className="mt-8">
                <Label>{f('comments')}</Label>
                <Textarea placeholder={f('commentsPlaceholder')} {...register('preferenceComment')} />
            </div>
        </Card>
    );
};

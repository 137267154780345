import { useMutation } from '@apollo/client';
import clsx from 'clsx';
import { Icon } from 'components/Icon/Icon';
import { useToast } from 'components/Toast/use-toast';
import { UserSuspendModal } from 'components/UserSuspendModal/UserSuspendModal';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { BLOCK_MEMBER, MEMBER_SUSPEND, MEMBER_UNSUSPEND, RESET_PASSWORD, USER_DELETE } from 'services/users/mutation';

import { BlockMemberModal } from '../BlockMemberModal/BlockMemberModal';
import { UnblockMemberModal } from '../UnblockMemberModal/UnblockMemberModal';
import { UserDeleteModal } from '../UserDeleteModal/UserDeleteModal';

import styles from './UserSettings.module.scss';

type UserSettingsProps = {
    user: any;
    actions: string[];
    onChange?: () => void;
    navigateTo?: string;
};

export const UserSettings: React.FC<UserSettingsProps> = ({ user, actions, onChange, navigateTo }) => {
    const menu = useRef<any>(null);
    const [isActive, setIsActive] = useState(false);
    const [blockModal, setBlockModal] = useState(false);
    const [unblockModal, setUnblockModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [suspendModal, setSuspendModal] = useState(false);
    const { t } = useTranslation('member', { keyPrefix: 'userSettings' });
    const { toast } = useToast();
    const navigate = useNavigate();

    const isMemberBlocked = user?.status === 'BLOCKED';
    const isMemberSuspended = user?.status === 'SUSPENDED';

    const [blockUnblock] = useMutation(BLOCK_MEMBER);
    const [resetPassword] = useMutation(RESET_PASSWORD);
    const [deleteUser] = useMutation(USER_DELETE);
    const [suspendUser] = useMutation(MEMBER_SUSPEND);
    const [unsuspendUser] = useMutation(MEMBER_UNSUSPEND);

    const handleClickOutside = (event: any) => {
        if (menu.current && !menu.current.contains(event.target)) {
            setIsActive(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    // PASWORD RESET LOGIC
    const handlePasswordReset = () => {
        const userEmail = user?.email;
        resetPassword({ variables: { email: userEmail } }).then(r => {
            if (r.data.resetPassword.success) {
                toast({
                    title: t('passwordResetSuccessTitle'),
                    description: t('passwordResetSuccessText').replace('{email}', user ? user?.email : ''),
                    variant: 'success',
                });
            } else {
                failedToast();
            }
        });
    };

    // PERMANENTLY DELETE LOGIC
    const handleDeleteUser = () => {
        const userId = user?.id;
        deleteUser({ variables: { user: userId } }).then(r => {
            if (r.data.userDelete.success) {
                setDeleteModal(false);
                toast({
                    title: t('deleteSuccess'),
                    variant: 'success',
                });
                navigate(navigateTo || AppRoutes.members, { replace: true });
            } else {
                failedToast();
            }
        });
    };

    // BLOCK UNBLOCK LOGIC
    const handleBlockUnblockMember = () => {
        isMemberBlocked ? setUnblockModal(true) : setBlockModal(true);
    };

    const blockMember = () => {
        const userId = user?.id;
        blockUnblock({ variables: { user: userId, action: 'BLOCK' } }).then(r => {
            if (r.data.userBlock) {
                toast({
                    description: t('blockSuccess'),
                    variant: 'success',
                });
            } else {
                failedToast();
            }
            if (onChange) onChange();

            setBlockModal(false);
        });
    };

    const unblockMember = () => {
        const userId = user?.id;
        blockUnblock({ variables: { user: userId, action: 'UNBLOCK' } }).then(r => {
            if (r.data.userBlock) {
                toast({
                    description: t('unblockSuccess'),
                    variant: 'success',
                });
            } else {
                failedToast();
            }
            if (onChange) onChange();
            setUnblockModal(false);
        });
    };

    const handleSuspend = (data: any) => {
        console.log(data);
        const userId = user?.id;
        suspendUser({ variables: { member: userId, ...data } }).then(r => {
            if (r.data.memberSuspend?.status) {
                toast({
                    title: t('suspendSuccess'),
                    variant: 'success',
                });
                setSuspendModal(false);
                if (onChange) onChange();
            } else {
                failedToast();
            }
        });
    };

    const handleUnsuspend = () => {
        const userId = user?.id;
        unsuspendUser({ variables: { member: userId } }).then(r => {
            if (r.data.memberUnsuspend?.status) {
                toast({
                    title: t('unsuspendSuccess'),
                    variant: 'success',
                });
                if (onChange) onChange();
            } else {
                failedToast();
            }
        });
    };

    const failedToast = () => {
        toast({
            title: t('failToastTitle'),
            description: t('failToastText'),
            variant: 'warning',
        });
    };

    return (
        <div
            className={clsx(styles.userSettings, isActive && styles.active)}
            onClick={() => setIsActive(!isActive)}
            ref={menu}
        >
            <div className={styles.trigger}>
                {t('settings')} <Icon name="carret-down" />
            </div>

            <div className={styles.dropdown}>
                <div className={styles.dropdownContent}>
                    {actions.includes('BLOCK') && (
                        <div className={styles.dropdownItem} onClick={() => handleBlockUnblockMember()}>
                            <Icon name="block" /> <span>{isMemberBlocked ? t('unblockMember') : t('blockMember')}</span>
                        </div>
                    )}
                    {actions.includes('PASSWORD_RESET') && (
                        <div className={styles.dropdownItem} onClick={() => handlePasswordReset()}>
                            <Icon name="lock" /> <span>{t('passwordReset')}</span>
                        </div>
                    )}

                    {/* <div className={styles.dropdownItem}>
                        <Icon name="export" /> <span>{t('export')}</span>
                    </div> */}
                    {actions.includes('SUSPEND') && (
                        <>
                            {isMemberSuspended && (
                                <div className={styles.dropdownItem} onClick={handleUnsuspend}>
                                    <Icon name="suspend" /> <span>{t('unsuspend')}</span>
                                </div>
                            )}
                            {!isMemberSuspended && (
                                <div className={styles.dropdownItem} onClick={() => setSuspendModal(true)}>
                                    <Icon name="suspend" /> <span>{t('suspend')}</span>
                                </div>
                            )}
                        </>
                    )}
                    {actions.includes('DELETE') && (
                        <div className={styles.dropdownItem} onClick={() => setDeleteModal(true)}>
                            <Icon name="trash" /> <span>{t('deletePermanently')}</span>
                        </div>
                    )}
                </div>
            </div>

            <BlockMemberModal isOpen={blockModal} onClose={() => setBlockModal(false)} onBlock={blockMember} />

            <UnblockMemberModal
                isOpen={unblockModal}
                onClose={() => setUnblockModal(false)}
                onUnblock={unblockMember}
            />

            <UserDeleteModal
                isOpen={deleteModal}
                onClose={() => setDeleteModal(false)}
                onDelete={handleDeleteUser}
                user={user}
            />
            <UserSuspendModal isOpen={suspendModal} onClose={() => setSuspendModal(false)} onSuspend={handleSuspend} />
        </div>
    );
};

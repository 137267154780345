import { Card, CardTitle } from 'components/Card/Card';
import { FamilyMemberBox } from 'components/FamilyMemberBox/FamilyMemberBox';
import { ViewButton } from 'components/ViewButton/ViewButton';
import { normalizeArray } from 'lib/utils';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from 'Routes';
import { useUserStore } from 'stores/user.store';

export const MemberPreviewFamily = () => {
    const user = useUserStore(state => state.user);
    const { t } = useTranslation('member');

    if (!user || (!user.children.edges.length && !user.pets.edges.length)) return null;
    return (
        <Card className="flex flex-col gap-8">
            <div className="flex justify-between">
                <CardTitle>{t('familyTitle')}</CardTitle>
                <ViewButton to={`${AppRoutes.memberProfile.replace(':id', user.id)}#family`} />
            </div>
            <div className="flex flex-col gap-6">
                {user.children.edges.length > 0 && (
                    <div className="flex flex-col gap-4">
                        <div className="text-sm text-zinc-700">Children</div>
                        <div className="grid grid-cols-2 gap-6">
                            {normalizeArray(user?.children)?.map((child: any) => (
                                <FamilyMemberBox member={child} key={child.id} editeable={false} />
                            ))}
                        </div>
                    </div>
                )}

                {user.pets.edges.length > 0 && (
                    <div className="flex flex-col gap-4">
                        <div className="text-sm text-zinc-700">Pets</div>
                        <div className="grid grid-cols-2 gap-6">
                            {normalizeArray(user?.pets).map((pet: any) => (
                                <FamilyMemberBox member={pet} key={pet.id} editeable={false} />
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </Card>
    );
};

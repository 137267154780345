import { InputMask } from '@react-input/mask';
import { Button } from 'components/Button/Button';
import { FormError } from 'components/FormError/FormError';
import { Icon } from 'components/Icon/Icon';
import { Input } from 'components/Input/Input';
import { Label } from 'components/Label/Label';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { Select } from 'components/Select/Select';
import dayjs from 'dayjs';
import { GENDERS } from 'lib/options';
import { validateDate } from 'lib/utils';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUserStore } from 'stores/user.store';

type AddChildModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: (child: any) => void;
    child?: any;
    onRemove?: () => void;
};

export const AddChildModal: React.FC<AddChildModalProps> = ({ isOpen, onClose, onConfirm, child, onRemove }) => {
    const loading = useUserStore(state => state.editLoading);
    const { t } = useTranslation('member', { keyPrefix: 'addChildren' });
    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors, isValid },
    } = useForm({
        mode: 'onChange',
    });

    useEffect(() => {
        if (child) {
            child.dateOfBirth = child.dateOfBirth && dayjs(child.dateOfBirth).format('DD.MM.YYYY');
            const { id, ...rest } = child;
            reset(rest);
        }
    }, [child, reset]);

    const onSubmit = async (formData: any) => {
        if (formData.dateOfBirth) {
            formData.dateOfBirth = dayjs(formData.dateOfBirth, 'DD.MM.YYYY').format('YYYY-MM-DD');
        }
        onConfirm(formData);
    };

    const closeModal = () => {
        onClose();
        reset();
    };

    return (
        <>
            <Portal>
                <Modal isOpen={isOpen} onClose={onClose} maxWidth={800} uncloseable>
                    <div className="flex flex-col gap-8">
                        <div>
                            <div className="text-xl font-semibold text-zinc-700 mb-2">
                                {child ? t('editTitle') : t('title')}
                            </div>
                            <div className="text-sm text-zinc-500">{t('subtitle')}</div>
                        </div>
                        <form>
                            <div className="flex flex-col gap-6">
                                <div className="flex gap-6">
                                    <div className="flex-1">
                                        <Label>{t('firstName')}</Label>
                                        <Input
                                            {...register('firstName', {
                                                required: t('firstNameError'),
                                            })}
                                            placeholder={t('firstName')}
                                            error={!!errors.firstName}
                                        />
                                        <FormError error={errors.firstName} />
                                    </div>
                                    <div className="flex-1">
                                        <Label>{t('lastName')}</Label>
                                        <Input
                                            {...register('lastName', {
                                                required: t('lastNameError'),
                                            })}
                                            placeholder={t('lastName')}
                                            error={!!errors.lastName}
                                        />
                                        <FormError error={errors.lastName} />
                                    </div>
                                </div>
                                <div className="flex gap-6">
                                    <div className="flex-1">
                                        <Label>{t('dateOfBirth')}</Label>
                                        <InputMask
                                            component={Input}
                                            placeholder={'DD.MM.YYYY'}
                                            mask="__.__.____"
                                            replacement={{ _: /\d/ }}
                                            {...register('dateOfBirth', {
                                                validate: value => validateDate(value) || t('invalidDateError'),
                                                required: t('dateOfBirthError'),
                                            })}
                                            error={!!errors.dateOfBirth}
                                        />
                                        <FormError error={errors.dateOfBirth} />
                                    </div>
                                    <div className="flex-1">
                                        <Label>{t('gender')}</Label>
                                        <Controller
                                            control={control}
                                            name="gender"
                                            rules={{ required: t('genderError') }}
                                            render={({ field: { onChange } }) => (
                                                <Select
                                                    isSearchable={false}
                                                    placeholder={t('genderPlaceholder')}
                                                    options={GENDERS}
                                                    onChange={(val: any) => onChange(val.value)}
                                                    defaultValue={GENDERS.find(g => child?.gender === g.value)}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    error={!!errors.gender}
                                                />
                                            )}
                                        />
                                        <FormError error={errors.gender} />
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="flex justify-between gap-2 items-center">
                            {child && (
                                <div className="text-sm text-rose-700 hover:cursor-pointer" onClick={onRemove}>
                                    <Icon name="trash" />{' '}
                                    <span className="underline font-medium">{t('removeButton')}</span>
                                </div>
                            )}
                            <div className="flex gap-2 flex-1 justify-end">
                                <Button variant="outline" type="button" onClick={() => closeModal()}>
                                    {t('cancelButton')}
                                </Button>
                                <Button disabled={!isValid || loading} type="submit" onClick={handleSubmit(onSubmit)}>
                                    {t('confirmButton')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </Portal>
        </>
    );
};

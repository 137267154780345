import { useTranslation } from 'react-i18next';

export const Status = ({ status }: { status: string }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'status' });
    const getColor = () => {
        switch (status) {
            case 'ACTIVE':
            case 'UPCOMING':
            case 'PAID':
                return ['text-green-700', 'bg-green-700'];
            case 'BLOCKED':
            case 'SUSPENDED':
            case 'CANCELED':
            case 'INACTIVE':
            case 'OVERDUE':
                return ['text-orange-700', 'bg-orange-700'];
            case 'DRAFT':
                return ['text-zinc-900', 'bg-zinc-700'];
            case 'CONCLUDED':
                return ['text-blue-500', 'bg-blue-500'];
            default:
                return ['text-zinc-900', 'bg-zinc-700'];
        }
    };

    return (
        <div className={`flex items-center gap-1 text-sm ${getColor()[0]}`}>
            <div className={`w-2 h-2 rounded-full ${getColor()[1]}`} />
            {t(status)}
        </div>
    );
};

import { useMutation } from '@apollo/client';
import { Icon } from 'components/Icon/Icon';
import { useToast } from 'components/Toast/use-toast';
import dayjs from 'dayjs';
import { AddChildModal } from 'pages/NewMember/_components/AddChildModal/AddChildModal';
import { AddPetModal } from 'pages/NewMember/_components/AddPetModal/AddPetModal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DELETE_CHILD, DELETE_PET, EDIT_CHILD, EDIT_PET } from 'services/users/mutation';

import { RemoveConfirmModal } from './RemoveConfirmModal';

export const FamilyMemberBox = ({ member, editeable, onUpdate }: any) => {
    const [edit, setEdit] = useState(false);
    const [editPet, setEditPet] = useState(false);
    const [isRemove, setIsRemove] = useState(false);
    const [updateChild] = useMutation(EDIT_CHILD);
    const [updatePet] = useMutation(EDIT_PET);
    const [deleteChild] = useMutation(DELETE_CHILD);
    const [deletePet] = useMutation(DELETE_PET);
    const { t } = useTranslation('member');
    const { toast } = useToast();
    const isChild = !!member.gender;
    if (!member) return null;

    const getName = () => {
        if (member.name) {
            return member.name;
        } else {
            return `${member.firstName} ${member.lastName}`;
        }
    };

    const getIcon = () => {
        if (member.breed) {
            switch (member.type) {
                case 'CAT':
                    return 'cat';
                case 'DOG':
                    return 'dog';
                default:
                    return 'pet-other';
            }
        } else {
            switch (member.gender) {
                case 'MALE':
                    return 'male';
                case 'FEMALE':
                    return 'female';
                default:
                    return 'person';
            }
        }
    };

    const toggleEdit = () => {
        member.gender ? setEdit(true) : setEditPet(true);
    };

    const editMe = (formData: any) => {
        formData.gender ? handleEditChild(formData) : handleEditPet(formData);
    };

    const handleEditChild = (formData: any) => {
        const { __typename, ...rest } = formData;
        updateChild({ variables: { child: member.id, input: rest } }).then(resp => {
            if (resp.data.memberChildUpdate?.child) {
                toast({
                    title: t('familyInfo.updateChildSuccess'),
                    variant: 'success',
                });
                setEdit(false);
                onUpdate();
            } else {
                failedToast();
            }
        });
    };

    const handleEditPet = (formData: any) => {
        const { __typename, ...rest } = formData;
        updatePet({ variables: { pet: member.id, input: rest } }).then(resp => {
            if (resp.data.memberPetUpdate?.pet) {
                toast({
                    title: t('familyInfo.updatePetSuccess'),
                    variant: 'success',
                });
                setEdit(false);
                onUpdate();
            } else {
                failedToast();
            }
        });
    };

    const onRemove = () => {
        setEdit(false);
        setEditPet(false);
        setIsRemove(true);
    };

    const confirmRemove = () => {
        isChild ? handleRemoveChild() : handleRemovePet();
    };

    const handleRemoveChild = () => {
        deleteChild({ variables: { child: member.id } }).then(resp => {
            if (resp.data.memberChildDelete?.success) {
                toast({
                    title: t('familyInfo.deleteChildSuccess'),
                    variant: 'success',
                });
                setIsRemove(false);
                onUpdate();
            } else {
                failedToast();
            }
        });
    };

    const handleRemovePet = () => {
        deletePet({ variables: { pet: member.id } }).then(resp => {
            if (resp.data.memberPetDelete?.success) {
                toast({
                    title: t('familyInfo.deletePetSuccess'),
                    variant: 'success',
                });
                setIsRemove(false);
                onUpdate();
            } else {
                failedToast();
            }
        });
    };

    const failedToast = () => {
        toast({
            title: t('updateErrorTitle'),
            description: t('updateErrorMessage'),
            variant: 'warning',
        });
    };

    return (
        <>
            <div className="p-4 border border-rose-100 bg-white">
                <div className="flex">
                    <div className="flex-1 flex flex-col gap-2">
                        <div className="text-zinc-700 font-bold">{getName()}</div>
                        <div className="text-sm text-zinc-500 flex gap-3 items-center">
                            <div>{member.breed || dayjs(member.dateOfBirth).format('DD.MM.YYYY')}</div>
                            <div>|</div>
                            <div>
                                <Icon className="h-[14px] block" name={getIcon()} />
                            </div>
                        </div>
                    </div>
                    {editeable && (
                        <div>
                            <div
                                className="text-sm leading-3 text-rose-700 flex gap-1 items-center hover:cursor-pointer"
                                onClick={toggleEdit}
                            >
                                <Icon name="edit" />{' '}
                                <span className="font-semibold underline">{t('familyInfo.editButton')}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <AddChildModal
                isOpen={edit}
                onClose={() => setEdit(false)}
                onConfirm={editMe}
                child={member}
                onRemove={onRemove}
            />
            <AddPetModal
                isOpen={editPet}
                onClose={() => setEditPet(false)}
                onConfirm={editMe}
                pet={member}
                onRemove={onRemove}
            />
            <RemoveConfirmModal
                isOpen={isRemove}
                onClose={() => setIsRemove(false)}
                onConfirm={confirmRemove}
                isChild={!!member.gender}
            />
        </>
    );
};

import { AuthLayout } from 'components/AuthLayout/AuthLayout';
import { CompanyLayout } from 'components/Layouts/CompanyLayout';
import { DocumentsLayout } from 'components/Layouts/DocumentsLayout';
import { EventLayout } from 'components/Layouts/EventLayout';
import { Layout } from 'components/Layouts/Layout';
import { MemberLayout } from 'components/Layouts/MemberLayout';
import { StaffLayout } from 'components/Layouts/StaffLayout';
import Cookies from 'js-cookie';
import { Announcement } from 'pages/Announcement/Announcement';
import { CompaniesList } from 'pages/CompaniesList/CompaniesList';
import { CompanyContacts } from 'pages/CompanyContacts/CompanyContacts';
import { CompanyPreview } from 'pages/CompanyPreview/CompanyPreview';
import { CompanyProfile } from 'pages/CompanyProfile/CompanyProfile';
import { Consumptions } from 'pages/Consumptions/Consumptions';
import { ContactsList } from 'pages/ContactsList/ContactsList';
import { Dashboard } from 'pages/Dashboard/Dashboard';
import { EditAnnouncement } from 'pages/EditAnnouncement/EditAnnouncement';
import { EditCompany } from 'pages/EditCompany/EditCompany';
import { EditEvent } from 'pages/EditEvent/EditEvent';
import { EditMember } from 'pages/EditMember/EditMember';
import { EditPartner } from 'pages/EditPartner/EditPartner';
import { EditStaff } from 'pages/EditStaff/EditStaff';
import { EventPreview } from 'pages/EventPreview/EventPreview';
import { EventsList } from 'pages/EventsList/EventsList';
import { ForgotPassword } from 'pages/ForgotPassword/ForgotPassword';
import { Invoices } from 'pages/Invoices/Invoices';
import { Logout } from 'pages/Logout/Logout';
import { MemberContacts } from 'pages/MemberContacts/MemberContacts';
import { MemberInvoices } from 'pages/MemberInvoices/MemberInvoices';
import { MemberPartner } from 'pages/MemberPartner/MemberPartner';
import { MemberPreview } from 'pages/MemberPreview/MemberPreview';
import { MemberProfile } from 'pages/MemberProfile/MemberProfile';
import { MembersList } from 'pages/MembersList/MembersList';
import { NewCompany } from 'pages/NewCompany/NewCompany';
import { NewEvent } from 'pages/NewEvent/NewEvent';
import { NewMember } from 'pages/NewMember/NewMember';
import { NewStaff } from 'pages/NewStaff/NewStaff';
import { ResetPassword } from 'pages/ResetPassword/ResetPassword';
import { StaffList } from 'pages/StaffList/StaffList';
import { StaffProfile } from 'pages/StaffProfile/StaffProfile';
import React from 'react';
import { Routes as ReactRoutes, Route, Navigate } from 'react-router-dom';

import { Login } from './pages/Login/Login';

export enum AppRoutes {
    login = '/login',
    logout = '/logout',
    forgotPassword = '/forgot-password',
    resetPassword = '/password-reset',
    dashboard = '/',
    members = '/members',
    newMember = '/members/new',
    memberLayout = '/members',
    memberPreview = '/members/:id',
    memberProfile = '/members/:id/profile',
    editMember = '/members/:id/profile/edit',
    memberPartner = '/members/:id/partner',
    memberEditPartner = '/members/:id/partner/edit',
    memberContacts = '/members/:id/contacts',
    companies = '/members/companies',
    companyPreview = '/members/companies/:id',
    companyProfile = '/members/companies/:id/profile',
    editCompany = '/members/companies/:id/profile/edit',
    newCompany = '/members/companies/new',
    companyContacts = '/members/companies/:id/contacts',
    contacts = '/members/contacts',
    staff = '/staff',
    newStaff = '/staff/new',
    staffProfile = '/staff/:id/profile',
    editStaff = '/staff/:id/edit',
    events = '/events',
    event = '/event',
    eventPreview = '/events/:id',
    newEvent = '/events/new',
    editEvent = '/events/:id/edit',
    announcement = '/announcement',
    editAnnouncement = '/announcement/edit',
    consumptions = '/documents/consumptions',
    invoices = '/documents',
    memberInvoices = '/documents/invoices/:id',
}

const PriveteRoutes = () => {
    const auth = Cookies.get('token');

    return auth ? <Layout /> : <Navigate to={AppRoutes.login} />;
};
export const Routes = () => {
    return (
        <ReactRoutes>
            <Route element={<AuthLayout />}>
                <Route path={AppRoutes.login} element={<Login />} />
                <Route path={AppRoutes.forgotPassword} element={<ForgotPassword />} />
                <Route path={AppRoutes.resetPassword} element={<ResetPassword />} />
            </Route>
            <Route element={<PriveteRoutes />}>
                <Route path={AppRoutes.dashboard} element={<Dashboard />} />
                <Route path={AppRoutes.members} element={<MembersList />} />
                <Route path={AppRoutes.newMember} element={<NewMember />} />
                <Route path={AppRoutes.memberLayout} element={<MemberLayout />}>
                    <Route path={AppRoutes.memberPreview} element={<MemberPreview />} />
                    <Route path={AppRoutes.memberProfile} element={<MemberProfile />} />
                    <Route path={AppRoutes.editMember} element={<EditMember />} />
                    <Route path={AppRoutes.memberPartner} element={<MemberPartner />} />
                    <Route path={AppRoutes.memberEditPartner} element={<EditPartner />} />
                    <Route path={AppRoutes.memberContacts} element={<MemberContacts />} />
                </Route>
                <Route path={AppRoutes.companies} element={<CompaniesList />} />
                <Route path={AppRoutes.newCompany} element={<NewCompany />} />
                <Route path={AppRoutes.companies} element={<CompanyLayout />}>
                    <Route path={AppRoutes.companyPreview} element={<CompanyPreview />} />
                    <Route path={AppRoutes.companyProfile} element={<CompanyProfile />} />
                    <Route path={AppRoutes.editCompany} element={<EditCompany />} />
                    <Route path={AppRoutes.companyContacts} element={<CompanyContacts />} />
                </Route>
                <Route path={AppRoutes.contacts} element={<ContactsList />} />
                <Route path={AppRoutes.staff} element={<StaffList />} />
                <Route path={AppRoutes.newStaff} element={<NewStaff />} />
                <Route path={AppRoutes.staff} element={<StaffLayout />}>
                    <Route path={AppRoutes.staffProfile} element={<StaffProfile />} />
                    <Route path={AppRoutes.editStaff} element={<EditStaff />} />
                </Route>
                <Route path={AppRoutes.events} element={<EventsList />} />
                <Route path={AppRoutes.newEvent} element={<NewEvent />} />
                <Route path={AppRoutes.events} element={<EventLayout />}>
                    <Route path={AppRoutes.eventPreview} element={<EventPreview />} />
                    <Route path={AppRoutes.editEvent} element={<EditEvent />} />
                </Route>
                <Route path={AppRoutes.announcement} element={<Announcement />} />
                <Route path={AppRoutes.editAnnouncement} element={<EditAnnouncement />} />
                <Route path={AppRoutes.invoices} element={<DocumentsLayout />}>
                    <Route path={AppRoutes.invoices} element={<Invoices />} />
                    <Route path={AppRoutes.consumptions} element={<Consumptions />} />
                    <Route path={AppRoutes.memberInvoices} element={<MemberInvoices />} />
                </Route>
            </Route>
            <Route path={AppRoutes.logout} element={<Logout />} />
        </ReactRoutes>
    );
};

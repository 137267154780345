import { useMutation } from '@apollo/client';
import { Button } from 'components/Button/Button';
import { useToast } from 'components/Toast/use-toast';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { MEMBER_REMOVE, MEMBERS_ATTACH } from 'services/companies/mutation';

import { ConfirmCompanyModal } from '../ConfirmCompanyModal/ConfirmCompanyModal';
import { ConfirmDetachCompanyModal } from '../ConfirmDetachCompanyModal/ConfirmDetachCompanyModal';
import { ExistingCompanyModal } from '../ExistingCompanyModal/ExistingCompanyModal';

import { MembershipTypeModal } from './MembershipTypeModal';

export const CompanyMembership = ({ user, onUpdate }: any) => {
    const [typeModal, setTypeModal] = useState(false);
    const [existingModal, setExistingModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [confirmDetachModal, setConfirmDetachModal] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState<string | null>(null);

    const navigate = useNavigate();
    const { toast } = useToast();
    const { t } = useTranslation('member', { keyPrefix: 'companyMembership' });
    const { t: m } = useTranslation('member');

    const [memberAttach] = useMutation(MEMBERS_ATTACH);
    const [memberDetach] = useMutation(MEMBER_REMOVE);

    const selectType = (type: string) => {
        setTypeModal(false);
        if (type === 'EXISTING') setExistingModal(true);
        if (type === 'NEW') setConfirmModal(true);
    };

    const selectCompany = (id: string | null) => {
        if (id) {
            setSelectedCompany(id);
            setExistingModal(false);
            setConfirmModal(true);
        }
    };

    const confirmCompany = () => {
        if (!user) return;
        if (selectedCompany) {
            memberAttach({
                variables: { members: [user.id], company: selectedCompany },
            }).then(r => {
                console.log(r);
                if (r.data?.companyMemberAdd?.success) {
                    setConfirmModal(false);
                    toast({
                        title: t('attachSuccess'),
                        variant: 'success',
                    });
                    navigate(AppRoutes.companyPreview.replace(':id', selectedCompany));
                } else {
                    failedToast();
                }
            });
        } else {
            navigate(`${AppRoutes.newCompany}?member=${user.id}`);
        }
    };

    const confirmDetach = () => {
        if (!user || !user?.company) return;

        memberDetach({
            variables: { member: user.id, company: user?.company?.id },
        }).then(r => {
            if (r.data?.companyMemberRemove?.success) {
                toast({
                    title: t('detachSuccess'),
                    variant: 'success',
                });
                onUpdate();
            } else {
                failedToast();
            }
        });
    };

    const failedToast = () => {
        toast({
            title: m('updateErrorTitle'),
            description: m('updateErrorMessage'),
            variant: 'warning',
        });
    };

    return (
        <>
            {user?.company ? (
                <Button type="button" variant="outline" onClick={() => setConfirmDetachModal(true)}>
                    {t('detachButton')}
                </Button>
            ) : (
                <Button type="button" variant="outline" onClick={() => setTypeModal(true)}>
                    {t('button')}
                </Button>
            )}

            <MembershipTypeModal isOpen={typeModal} onClose={() => setTypeModal(false)} onSelect={selectType} />
            <ExistingCompanyModal
                isOpen={existingModal}
                onClose={() => setExistingModal(false)}
                onSelect={selectCompany}
            />
            <ConfirmCompanyModal
                isOpen={confirmModal}
                onClose={() => setConfirmModal(false)}
                onConfirm={confirmCompany}
            />
            <ConfirmDetachCompanyModal
                isOpen={confirmDetachModal}
                onClose={() => setConfirmDetachModal(false)}
                onConfirm={confirmDetach}
            />
        </>
    );
};

import { Card, CardTitle } from 'components/Card/Card';
import { PreviewLabel, PreviewValue } from 'components/PreviewFields/PreviewFields';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useUserStore } from 'stores/user.store';

export const PersonalInfoPreview = () => {
    const user = useUserStore(state => state.user);
    const { t } = useTranslation('forms');
    const { t: p } = useTranslation('member', { keyPrefix: 'personalInfo' });
    return (
        <Card className="flex-1 flex flex-col gap-8">
            <CardTitle>{p('title')}</CardTitle>

            <div className="flex flex-col gap-6">
                <div className="flex gap-6">
                    <div className="flex-1">
                        <PreviewLabel>{t('preview.firstName')}</PreviewLabel>
                        <PreviewValue>{user?.firstName}</PreviewValue>
                    </div>
                    <div className="flex-1">
                        <PreviewLabel>{t('preview.lastName')}</PreviewLabel>
                        <PreviewValue>{user?.lastName}</PreviewValue>
                    </div>
                </div>

                <div className="flex gap-6">
                    <div className="flex-1">
                        <PreviewLabel>{t('preview.salutation')}</PreviewLabel>
                        <PreviewValue className="capitalize">{user?.salutation?.toLowerCase()}.</PreviewValue>
                    </div>
                    <div className="flex-1">
                        <PreviewLabel>{t('preview.dateOfBirth')}</PreviewLabel>
                        <PreviewValue>{dayjs(user?.dateOfBirth).format('DD.MM.YYYY')}</PreviewValue>
                    </div>
                </div>

                <div className="flex gap-6">
                    <div className="flex-1">
                        <PreviewLabel>{t('preview.email')}</PreviewLabel>
                        <PreviewValue>{user?.email}</PreviewValue>
                    </div>
                    <div className="flex-1">
                        <PreviewLabel>{t('preview.phone')}</PreviewLabel>
                        <PreviewValue>{user?.phoneNumber}</PreviewValue>
                    </div>
                </div>

                <div className="flex gap-6">
                    <div className="flex-1 flex gap-6">
                        <div className="flex-1">
                            <PreviewLabel>{t('preview.entry')}</PreviewLabel>
                            <PreviewValue>
                                {(user?.membershipStart && dayjs(user?.membershipStart).format('DD.MM.YYYY')) || '-'}
                            </PreviewValue>
                        </div>
                        <div className="flex-1">
                            <PreviewLabel>{t('exit')}</PreviewLabel>
                            <PreviewValue>
                                {(user?.membershipEnd && dayjs(user?.membershipEnd).format('DD.MM.YYYY')) || '-'}
                            </PreviewValue>
                        </div>
                    </div>
                    <div className="flex-1">
                        <PreviewLabel>{t('referencePerson')}</PreviewLabel>
                        <PreviewValue>
                            {user?.referencePerson?.firstName || '-'} {user?.referencePerson?.lastName}
                        </PreviewValue>
                    </div>
                </div>
            </div>
        </Card>
    );
};

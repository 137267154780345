import { cn } from 'lib/utils';

export const Balance = ({ balance }: any) => {
    if (!balance) return <span>CHF 0.00</span>;
    return (
        <span className={cn(balance?.amount < 0 && 'text-orange-700')}>
            {balance.amount.toLocaleString('de-CH', { style: 'currency', currency: balance.currency })}
        </span>
    );
};

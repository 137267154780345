import { Tab, Tabs } from 'components/Tabs/Tabs';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AppRoutes } from 'Routes';

export const MemberTabs = () => {
    const { t } = useTranslation('translation');
    const { id } = useParams();

    if (!id) return null;

    return (
        <Tabs>
            <Tab to={AppRoutes.memberProfile.replace(':id', id)}>{t('tabs.profile')}</Tab>
            <Tab to={AppRoutes.memberPartner.replace(':id', id)}>{t('tabs.partner')}</Tab>
            <Tab to={AppRoutes.memberContacts.replace(':id', id)}>{t('tabs.contacts')}</Tab>
            {/* <Tab>{t('tabs.bookings')}</Tab> */}
            {/* <Tab>{t('tabs.events')}</Tab> */}
            {/* <Tab>{t('tabs.invoices')}</Tab> */}
        </Tabs>
    );
};

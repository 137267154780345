import { InputMask } from '@react-input/mask';
import { Button } from 'components/Button/Button';
import { Card, CardSubtitle, CardTitle } from 'components/Card/Card';
import { FormError } from 'components/FormError/FormError';
import { Input } from 'components/Input/Input';
import { Label } from 'components/Label/Label';
import { Select } from 'components/Select/Select';
import { useToast } from 'components/Toast/use-toast';
import dayjs from 'dayjs';
import { MATRIAL_STATUS } from 'lib/options';
import { validateDate } from 'lib/utils';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUserStore } from 'stores/user.store';

import { AddChildModal } from '../AddChildModal/AddChildModal';
import { AddPetModal } from '../AddPetModal/AddPetModal';
import { FamilyMembers } from '../FamilyMembers/FamilyMembers';

export const FamilyInfo = () => {
    const user = useUserStore(state => state.user);
    const getUser = useUserStore(state => state.getUser);
    const addChild = useUserStore(state => state.addChild);
    const addPet = useUserStore(state => state.addPet);
    const [showModal, setShowModal] = useState(false);
    const [showPetModal, setShowPetModal] = useState(false);
    const { toast } = useToast();

    const {
        control,
        register,
        setValue,
        formState: { errors },
    } = useFormContext();
    const { t } = useTranslation('member', { keyPrefix: 'familyInfo' });
    const { t: f } = useTranslation('forms');

    // Pre-fill fields if in edit mode
    useEffect(() => {
        if (user) {
            setValue('maritalStatus', user.maritalStatus);
            setValue('weddingDate', user.weddingDate && dayjs(user.weddingDate).format('DD.MM.YYYY'));
        }
    }, [user, setValue]);

    const onChildAdd = async (child: any) => {
        if (!user?.id) return null;
        const result = await addChild(child, user.id);
        if (result.data.memberChildCreate?.child) {
            getUser(user.id);
            setShowModal(false);
            toast({
                description: t('childAddSuccess'),
                variant: 'success',
            });
        }
        if (result.errors?.length > 0) {
            setShowModal(false);
            toast({
                description: result.errors[0].message,
                variant: 'warning',
            });
        }
    };

    const onPetAdd = async (pet: any) => {
        if (!user?.id) return null;
        const result = await addPet(pet, user.id);
        if (result.data.memberPetCreate?.pet) {
            getUser(user.id);
            setShowPetModal(false);
            toast({
                description: t('petAddSuccess'),
                variant: 'success',
            });
        }
        if (result.errors?.length > 0) {
            setShowPetModal(false);
            toast({
                description: result.errors[0].message,
                variant: 'warning',
            });
        }
    };

    return (
        <Card>
            <CardTitle>{t('title')}</CardTitle>
            <CardSubtitle>{t('subtitle')}</CardSubtitle>
            <div className="flex gap-6 mb-6">
                <div className="flex-1">
                    <Label>{f('maritalStatus')}</Label>
                    <Controller
                        control={control}
                        name="maritalStatus"
                        rules={{ required: f('maritalStatusRequired') }}
                        render={({ field: { onChange } }) => (
                            <Select
                                isSearchable={false}
                                placeholder={f('selectPlaceholder')}
                                options={MATRIAL_STATUS}
                                onChange={(val: any) => onChange(val.value)}
                                error={!!errors.maritalStatus}
                                defaultValue={MATRIAL_STATUS.find(m => m.value === user?.maritalStatus)}
                            />
                        )}
                    />
                    <FormError error={errors.maritalStatus} />
                </div>

                <div className="flex-1">
                    <Label>{f('weddingDate')}</Label>
                    <InputMask
                        component={Input}
                        placeholder={f('datePlaceholder')}
                        mask="__.__.____"
                        replacement={{ _: /\d/ }}
                        error={!!errors.weddingDate}
                        {...register('weddingDate', {
                            validate: value => validateDate(value) || f('invalidDateError'),
                        })}
                    />
                    <FormError error={errors.weddingDate} />
                </div>
            </div>
            <FamilyMembers />
            {user?.id && (
                <div className="mt-8 flex gap-2">
                    <Button type="button" variant="outline" onClick={() => setShowModal(true)}>
                        {t('addChildrenButton')}
                    </Button>
                    <Button type="button" variant="outline" onClick={() => setShowPetModal(true)}>
                        {t('addPets')}
                    </Button>
                </div>
            )}

            <AddChildModal
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                onConfirm={(child: any) => onChildAdd(child)}
            />
            <AddPetModal isOpen={showPetModal} onClose={() => setShowPetModal(false)} onConfirm={onPetAdd} />
        </Card>
    );
};

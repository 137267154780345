import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { Button } from 'components/Button/Button';
import { Card } from 'components/Card/Card';
import { Container } from 'components/Container/Container';
import { Icon } from 'components/Icon/Icon';
import { Input } from 'components/Input/Input';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { Textarea } from 'components/Textarea/Textarea';
import { CustomToggle } from 'components/Toggle/Toggle';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const EditAnnouncement = () => {
    const { t } = useTranslation('anouncement');
    const navigate = useNavigate();
    const { register, watch, handleSubmit, control } = useForm();

    const onSubmit = (formData: any) => {
        console.log(formData);
    };

    return (
        <Container>
            <Breadcrumbs crumbs={['dashboard', 'announcement', 'edit']} />
            <PageTitle>
                <div className="flex-1 flex items-center gap-2">
                    <Icon name="back" className="h-6 text-2xl hover:cursor-pointer" onClick={() => navigate(-1)} />
                    {t('title')}
                </div>
            </PageTitle>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mt-4">
                    <Card className="flex flex-col gap-6">
                        <div>
                            <div className="text-sm font-medium mb-2">{t('titleLabel')}</div>
                            <Input placeholder="Title" {...register('title')} />
                        </div>
                        <div>
                            <div className="text-sm font-medium mb-4">{t('descriptionLabel')}</div>
                            <Textarea placeholder="Description" {...register('description')} />
                            <div className="flex justify-end text-sm mt-2 text-zinc-400">
                                {watch('description')?.length || 0} / 350
                            </div>
                        </div>
                        <label className="flex items-center gap-2">
                            <Controller
                                control={control}
                                name="active"
                                defaultValue={false}
                                render={({ field: { onChange, value } }) => (
                                    <CustomToggle defaultChecked={value} icons={false} onChange={onChange} />
                                )}
                            />

                            <span className="text-sm font-medium">Active announcement</span>
                        </label>
                    </Card>
                </div>
                <div className="mt-8 flex justify-end">
                    <Button>{t('saveButton')}</Button>
                </div>
            </form>
        </Container>
    );
};

import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';
import { Loader } from 'components/Loader/Loader';
import { MemberHeader } from 'components/MemberHeader/MemberHeader';
import { MemberTabs } from 'components/MemberTabs/MemberTabs';
import { UserPreferencesPreview } from 'components/UserPreferencesPreview/UserPreferencesPreview';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { useUserStore } from 'stores/user.store';

import { CompanyInfoPreview } from './_components/CompanyInfoPreview/CompanyInfoPreview';
import { FamilyInfoPreview } from './_components/FamilyInfoPreview/FamilyInfoPreview';
import { MemberInfoPreview } from './_components/MemberInfoPreview/MemberInfoPreview';
import { PersonalInfoPreview } from './_components/PersonalInfoPreview/PersonalInfoPreview';

export const MemberProfile = () => {
    const user = useUserStore(state => state.user);
    const loading = useUserStore(state => state.loading);
    const navigate = useNavigate();
    const { id } = useParams();

    const editMember = () => {
        if (!id) return;
        navigate(AppRoutes.editMember.replace(':id', id));
    };

    if (loading && !user) return <Loader fullSize />;

    return (
        <>
            <Breadcrumbs crumbs={['dashboard', 'members', `${user?.firstName} ${user?.lastName}`]} />

            <MemberHeader>
                {user && (
                    <Button onClick={editMember}>
                        <Icon name="edit" className="h-4 mr-2" />
                        Edit
                    </Button>
                )}
            </MemberHeader>
            <MemberTabs />

            <div className="flex flex-col gap-6">
                <div className="flex gap-6">
                    <MemberInfoPreview />
                    <PersonalInfoPreview />
                </div>
                <FamilyInfoPreview />
                <CompanyInfoPreview />
                <UserPreferencesPreview user={user} />
            </div>
        </>
    );
};

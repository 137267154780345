import { useMutation } from '@apollo/client';
import clsx from 'clsx';
import { Icon } from 'components/Icon/Icon';
import { useToast } from 'components/Toast/use-toast';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { INVOICE_MARK_AS_PAID, INVOICE_SEND_REMINDER } from 'services/users/mutation';

import { ConfirmInvoicePaidModal } from './ConfirmInvoicePaidModal';
import styles from './MemberInvoicesActions.module.scss';

type MemberInvoicesActionsProps = {
    invoice: any;
    onUpdate: () => void;
};

export const MemberInvoicesActions: React.FC<MemberInvoicesActionsProps> = ({ invoice, onUpdate }) => {
    const [isActive, setIsActive] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const menu = useRef<any>(null);
    const { t } = useTranslation('documents');
    const { toast } = useToast();

    // MUTATIONS
    const [markAsPaid] = useMutation(INVOICE_MARK_AS_PAID);
    const [sendReminder] = useMutation(INVOICE_SEND_REMINDER);

    const handleClickOutside = (event: any) => {
        if (menu.current && !menu.current.contains(event.target)) {
            setIsActive(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const onDownload = () => {
        window.open(invoice?.document.url, '_blank');
    };

    const onSendReminder = async () => {
        console.log('SEND REMIDER');
        const resp = await sendReminder({
            variables: { invoice: invoice.id },
        });

        if (resp.data?.invoiceSendReminder?.success) {
            toast({
                title: t('sendReminderSuccess'),
                variant: 'success',
            });
            onUpdate();
        } else {
            failedToast();
        }
        setIsOpen(false);
    };

    const onSetPaid = async () => {
        const resp = await markAsPaid({
            variables: { invoice: invoice.id },
        });

        if (resp.data?.invoiceMarkAsPaid?.status) {
            toast({
                title: t('markAsPaidSuccess'),
                variant: 'success',
            });
            onUpdate();
        } else {
            failedToast();
        }
        setIsOpen(false);
    };

    const failedToast = () => {
        toast({
            title: t('errorToastTitle'),
            description: t('errorToastMessage'),
            variant: 'warning',
        });
    };

    return (
        <>
            <div
                className={clsx(styles.tableActions, isActive && styles.active)}
                onClick={() => setIsActive(!isActive)}
                ref={menu}
            >
                <div className={styles.trigger}>
                    <Icon name="three-dots" />
                </div>
                <div className={styles.dropdown}>
                    <div className={styles.dropdownContent}>
                        {invoice.status !== 'PAID' && (
                            <>
                                <div className={styles.dropdownItem} onClick={() => setIsOpen(true)}>
                                    <Icon name="check" /> {t('markInvoicePaidButton')}
                                </div>
                                <div className={styles.dropdownItem} onClick={onSendReminder}>
                                    <Icon name="mail" /> {t('sendReminderButton')}
                                </div>
                            </>
                        )}
                        <div className={styles.dropdownItem} onClick={onDownload}>
                            <Icon name="download" /> {t('downloadPDFButton')}
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmInvoicePaidModal isOpen={isOpen} onClose={() => setIsOpen(false)} onConfirm={onSetPaid} />
        </>
    );
};

import { Input } from 'components/Input/Input';
import { useSearchParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

type SeacrhFilterProps = {
    placeholder?: string;
};
export const SearchFilter: React.FC<SeacrhFilterProps> = ({ placeholder }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const searchValue = useDebouncedCallback((e: any) => {
        const params = new URLSearchParams(searchParams);
        if (e.target.value) {
            params.set('search', e.target.value);
        } else {
            params.delete('search');
        }
        setSearchParams(params);
    }, 500);

    return (
        <Input
            onChange={searchValue}
            placeholder={placeholder}
            small
            className="w-[332px]"
            defaultValue={searchParams.get('search') as any}
        />
    );
};

import { gql } from '@apollo/client';

export const EVENTS = gql`
    query events(
        $search: String
        $sort: EventSortEnum
        $offset: Int
        $first: Int
        $type: [EventType]
        $status: [EventStatus]
        $isFullCapacity: Boolean
    ) {
        events(
            search: $search
            sort: $sort
            offset: $offset
            first: $first
            type_In: $type
            status_In: $status
            isFullCapacity: $isFullCapacity
        ) {
            pageInfo {
                hasNextPage
            }
            edges {
                node {
                    id
                    type
                    name
                    date
                    status
                    fromTime
                    untilTime
                    allDay
                    capacity
                    confirmed {
                        totalCount
                    }
                    invited(first: 10) {
                        totalCount
                        edges {
                            node {
                                id
                                firstName
                                lastName
                                image {
                                    url
                                }
                            }
                        }
                    }
                }
            }
            totalCount
        }
    }
`;

export const EVENT = gql`
    query event($id: ID!) {
        event(id: $id) {
            id
            name
            date
            type
            status
            fromTime
            untilTime
            allDay
            capacity
            description
            comments
            confirmed {
                totalCount
                edges {
                    node {
                        id
                        firstName
                        lastName
                        image {
                            url
                        }
                    }
                }
            }
            invited(first: 10) {
                totalCount
                edges {
                    node {
                        id
                        firstName
                        lastName
                        image {
                            url
                        }
                    }
                }
            }
            images {
                id
                image {
                    url
                    alt
                }
            }
            documents {
                id
                name
                document {
                    url
                }
            }
        }
    }
`;

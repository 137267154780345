import { useQuery } from '@apollo/client';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { Icon } from 'components/Icon/Icon';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { SearchFilter } from 'components/SearchFilter/SearchFilter';
import { SortFilter } from 'components/SortFilter/SortFilter';
import { Status } from 'components/Status/Status';
import { StatusFilter } from 'components/StatusFilter/StatusFilter';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/Table/Table';
import dayjs from 'dayjs';
import { normalizeArray } from 'lib/utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { USERS_INVOICES } from 'services/users/query';
import { useUserStore } from 'stores/user.store';

import { MemberInvoicesActions } from './_components/MemberInvoicesActions';

const PAGE_SIZE = parseInt(localStorage.getItem('pageSize') as any) || 10;
const SORT = [
    { value: 'A_Z', label: 'A-Z' },
    { value: 'Z_A', label: 'Z-A' },
    { value: 'MOST_RECENT', label: 'Most recent' },
    { value: 'LEAST_RECENT', label: 'Least recent' },
];
const STATUSES = ['UNPAID', 'PAID', 'OVERDUE'];

export const MemberInvoices = () => {
    const user = useUserStore(state => state.user);
    const navigate = useNavigate();
    const { t } = useTranslation('documents');
    const [searchParams] = useSearchParams();

    const { data, refetch } = useQuery(USERS_INVOICES, {
        variables: { member: user?.id, first: PAGE_SIZE },
    });
    useEffect(() => {
        const search = searchParams.get('search');
        const sort = searchParams.get('sort');
        const status = searchParams.get('status')?.split(',');
        const page = searchParams.get('page') || '1';

        refetch({
            search: search,
            sort: sort,
            status: status,
            offset: (parseInt(page) - 1) * PAGE_SIZE,
        });
    }, [searchParams, refetch]);

    if (!user) return null;

    return (
        <div>
            <Breadcrumbs crumbs={['dashboard', 'invoices', `${user.firstName} ${user.lastName} invoices`]} />
            <PageTitle>
                <div className="flex-1 flex items-center gap-2">
                    <Icon name="back" className="h-6 text-2xl hover:cursor-pointer" onClick={() => navigate(-1)} />
                    {user.firstName} {user.lastName} invoices
                </div>
            </PageTitle>

            <div className="flex gap-4 flex-wrap pb-4">
                <SearchFilter placeholder={t('searchInvoicesPlaceholder')} />
                <StatusFilter options={STATUSES} />
                <SortFilter options={SORT} />
            </div>

            <Table className="border">
                <TableHeader>
                    <TableRow>
                        <TableHead>{t('dateLabel')}</TableHead>
                        <TableHead>{t('invoiceNumberLabel')}</TableHead>
                        <TableHead>{t('emailReminderLabel')}</TableHead>
                        <TableHead>{t('dueDateLabel')}</TableHead>
                        <TableHead>{t('invoiceStatusLabel')}</TableHead>
                        <TableHead />
                    </TableRow>
                </TableHeader>
                <TableBody className="hover:cursor-pointer">
                    {data?.invoices?.edges?.length > 0 &&
                        normalizeArray(data.invoices).map((invoice: any) => (
                            <TableRow key={invoice.id} className="text-zinc-700 hover:bg-rose-50">
                                <TableCell>{dayjs(invoice.createdAt).format('DD.MM.YYYY')}</TableCell>
                                <TableCell>{invoice.invoiceId}</TableCell>
                                <TableCell className="hidden md:table-cell">
                                    {invoice.lastReminder ? dayjs(invoice.lastReminder).format('DD.MM.YYYY') : '-'}
                                </TableCell>
                                <TableCell className="hidden md:table-cell">
                                    {dayjs(invoice.dueDate).format('DD.MM.YYYY')}
                                </TableCell>
                                <TableCell className="hidden md:table-cell">
                                    <Status status={invoice.status} />
                                </TableCell>
                                <TableCell className="py-3 justify-end flex">
                                    <MemberInvoicesActions invoice={invoice} onUpdate={() => refetch()} />
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            {data?.user?.invoices?.edges?.length === 0 && (
                <div className={'py-5 text-center text-zinc-700'}>{t('noResults')}</div>
            )}
        </div>
    );
};
